import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { GetServerSidePropsContext } from 'next';

import Benefits from '~/v2/components/others/Benefits';
import Layout from '~/v2/components/layout/Layout';
import SkeletonWrapper from '~/v2/components/wrapper/SkeletonWrapper';
import { fetchSliders } from '~/v2/modules/slider/slider.service';
import { BannerT } from '~/v2/modules/slider/slider.type';
import HomePageContent from '~/v2/parts/home/HomePageContent';

const HeroSliderOne = dynamic(() => import('../components/sections/hero-slider/HeroSliderOne'));
const Container = dynamic(() => import('../components/other/Container'));

export default function Home() {
  const [sliders, setSliders] = useState<BannerT[]>([]);

  useEffect(() => {
    (async () => {
      const result = await fetchSliders();

      if (result && result.data) {
        setSliders(result.data);
      }
    })();
  }, []);

  return (
    <Layout
      title='Beccato'
      head={
        <>
          <link rel='preload' href='https://www.beccato.com.my/assets/images/logo.png' as='image' />
          <meta name='title' content='Beccato Sdn Bhd' key='meta_title' />
          <meta
            name='description'
            content='We are committed to caring and sharing health with every single one of you. Our products are all chosen with 100% natural, vegan and no additives.'
          />

          <meta property='og:title' content='Beccato Sdn Bhd' key='og:title' />
          <meta property='og:url' content='https://www.beccato.com.my/' key='og:url' />
          <meta
            property='og:description'
            content='Beccato Global Sdn. Bhd. is a company which committed to caring and sharing ‘Healthy’ with every single one of you.'
            key='og:description'
          />
          <meta property='og:image' content='https://www.beccato.com.my/assets/images/logo.png' key='og:image' />

          <link rel='canonical' href='https://beccato.com.my' />
        </>
      }
    >
      <SkeletonWrapper delay={1000}>
        <HeroSliderOne
          data={sliders.map((item) => ({ background: item.image_url, mobileBackground: item.mobile_image_url }))}
        />
      </SkeletonWrapper>
      <Container>
        <Benefits />
      </Container>
      <Container>
        <HomePageContent />
      </Container>
    </Layout>
  );
}

export const getServerSideProps = async ({ res }: GetServerSidePropsContext) => {
  res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=119');

  return {
    props: {},
  };
};
